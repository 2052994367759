import React from 'react';
import Alert from 'react-bootstrap/Alert';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import EventsCol from './EventsCol'

export default class Events extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            events: [],
            authorized: this.props.authorized,
            userData: this.props.userData
        };
    }

    componentDidMount() {
        const requestOptions = {
            method: (!this.state.authorized ? 'GET' : 'POST'),
            credentials: 'include'
        };
        fetch("https://app.sparitesports.com/api/events", requestOptions)
            .then(res => res.json())
            .then(
                (events) => {
                    this.setState({
                        isLoaded: true,
                        events
                    });
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
    }

    render() {
        const {error, isLoaded, events, authorized, userData} = this.state;

        if (error) {
            return <div>:(<br/>Please refresh the page!</div>;
        } else if (!isLoaded) {
            return <Alert>&nbsp;</Alert>;
        } else if (events.length === 0) {
            return <Alert variant="secondary" className="text-center">
                <b>Events are not published at this time, please check later.</b><br/>
                (Usually events are published on Wednesdays.)<br/><br/>
                For more information follow us on Facebook page: <a
                href="https://www.facebook.com/sparite2.0">Facebook.com/Sparite2.0</a><br/>
            </Alert>;
        } else {
            return (
                <>
                    {events.length &&
                        <Row className="text-center">
                            <EventsCol events={events} login={authorized} userData={userData}/>
                        </Row>
                    }

                    {authorized &&
                        <Row>
                            <Col>
                                <Alert variant="secondary" className="text-center">
                                    By joining events, you agree with the terms in the event.
                                </Alert>
                            </Col>
                        </Row>
                    }

                    {!authorized &&
                        <>
                            <Alert variant="primary" className="text-center">
                                <b>Please login into Google profile to join events!</b><br/>
                            </Alert>
                            <br/>
                            <br/>
                        </>
                    }
                </>
            );
        }
    }
}
