import React from 'react';
import Col from 'react-bootstrap/Col';

export default class UpdateInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userData: this.props.userData
        };
    }

    showUpdateInfo(e) {
        const el = e.currentTarget;
        el.classList.add('d-none');
        document.getElementById("updateInfoForm").classList.add('d-block');
    }

    updateSettings(e) {
        e.preventDefault();
        const form = e.target;
        const email = e.target.email;
        const phone = e.target.phone;
        const notifyNewEvent = e.target.notifyNewEvent;
        const requestOptions = {
            method: 'POST',
            credentials: 'include',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                phone: phone.value,
                email: email.value,
                notifyNewEvent: notifyNewEvent.checked
            })
        };
        fetch('https://app.sparitesports.com/api/me/updateInfo', requestOptions)
            .then(response => response.json())
            .then((response) => {
                if (response.status === "error") {
                    if (response.code === 1001) {
                        email.classList.add('input-temp-error');
                    } else if (response.code === 1002) {
                        phone.classList.add('input-temp-error');
                    }
                } else {
                    form.classList.add('temp-success');
                }

                setTimeout(() => {
                    form.classList.remove('temp-success')
                    email.classList.remove('input-temp-error')
                    phone.classList.remove('input-temp-error')
                }, 1500)
            });
    }

    render() {
        const {userData} = this.state;
        // const missingInfo = !userData.phone && !userData.email;
        const missingInfo = true; // force to see input forms

        // console.log("missingInfo", missingInfo, !userData.phone, !userData.email)

        return (
            <>
            {/*
                <small style={{display: 'block', textAlign: 'center'}}>
                    {missingInfo &&
                        <>
                            Add your contact info.
                            <br/>
                        </>
                    }
                    <div className={`${missingInfo && "_d-md-none"}`}>
                        <button className={`btn btn-outline-dark`} style={{marginTop: "10px", marginBottom: "5px"}}
                                onClick={this.showUpdateInfo}>
                            Update Contact Info
                        </button>
                    </div>
                </small>
                 */}

                <form onSubmit={this.updateSettings.bind(this)} id="updateInfoForm"
                      className={`_d-none ${missingInfo && "d-md-block"}`}>
                    <div className="form-row">
                        <Col lg={4} md={5}>
                            <div className="form-label-group">
                                <input type="tel" className="form-control"
                                       id="inputPhone" name="phone" defaultValue={userData.phone}
                                       placeholder="Phone number" pattern="[+0-9]{8,14}" data-field-code="1001"/>
                                <label htmlFor="inputPhone">My Phone number</label>
                            </div>
                        </Col>

                        {/*<Col lg={4}>*/}
                        {/*    <div className="form-label-group">*/}
                        {/*        <input type="email" className="form-control"*/}
                        {/*               id="inputEmail" name="email" defaultValue={userData.email}*/}
                        {/*               placeholder="E-mail address" data-field-code="1002"/>*/}
                        {/*        <label htmlFor="inputEmail">My E-mail address</label>*/}
                        {/*    </div>*/}
                        {/*</Col>*/}

                        <Col lg={4}>
                            <div className="custom-control custom-checkbox">
                                <input type="checkbox" className="custom-control-input" name="notifyNewEvent"
                                       id="inputCheck" defaultChecked={userData.notifyNewEvent}/>
                                <label className="custom-control-label" htmlFor="inputCheck">Send new events</label>
                            </div>
                        </Col>

                        <Col lg={2} style={{textAlign: 'center'}}>
                            <button type="submit" className="btn btn-outline-primary mb-2">Update</button>
                        </Col>
                    </div>
                </form>
            </>
        );
    }
}
