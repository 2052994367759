import React from 'react';
import Button from "react-bootstrap/Button";

export default class EventPlayersTeams extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showTeams: false,
        };
    }

    render() {
        const {event, userData} = this.props;
        const {showTeams} = this.state;

        if (!event.playersList) {
            return;
        }

        return <>
            <hr/>
            {!showTeams && <div className="text-center" onClick={() => this.setState({showTeams: true})}>
                <Button variant="outline-secondary">
                    Show Teams
                </Button>
            </div>}

            {showTeams && (<div id="teamsBlock">
                <b>Teams</b>
                {this.teamsList(event)}
            </div>)}
        </>
    }


    teamsList = (event) => {
        let teams = {};
        // console.log ( event.playersList);

        event.playersList.forEach((player) => {
            if (player.type !== 'attendee') {
                return
            }

            if (teams[player.team] && teams[player.team]['players']) {
                teams[player.team]['players'].push(player);
            } else {
                teams[player.team] = {
                    'players': [player],
                    'totalRank': 0
                };
            }
        });

        let teamPlayersTotalRank = (players) => {
            let totalRank = 0;
            players.forEach(player => {
                totalRank += player.rank;
            });
            return totalRank;
        };

        Object.keys(teams).forEach((keyTeam) => {
            teams[keyTeam].totalRank = teamPlayersTotalRank(teams[keyTeam]['players']);
        });

        // console.log(teams);

        if (!teams) {
            return;
        }

        // let teamsSorted = {}
        // teams.forEach(function (item) {
        //     teamsSorted[item[0]] = item[1]
        // })

        let renderTeamPlayers = (teamName, playersList) => {
            return playersList.map((player) => {
                return <li key={player} style={{listStyleType: teamName === '' ? 'circle' : ''}}
                           className={player.user_id === this.props.userData.user_id ? 'me' : ''}>
                    {player.name}
                </li>;
            });
        }

        // let teamsSorted = teams;

        // teams.sort((a, b) => (a.totalRank > b.totalRank) ? 1 : -1)

        return Object.keys(teams).map((teamName, i) => {
            return <div key={teamName} className="teams">
                {teamName === "" ? <>
                    <br/>Without Team
                </> : teamName} <span className={"grayed"}>
                    ({teams[teamName]['totalRank']})
                </span>
                <div className="teamsPlayers">
                    {renderTeamPlayers(teamName, teams[teamName]['players'])}
                </div>
            </div>;
        });
    }
}
