import React from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import EventPlayers from "./EventPlayers";

export default class EventsCol extends React.Component {
    render() {
        const {events, login, userData} = this.props;

        const scrollTop = () => {
            window.scrollTo({top: 0, behavior: 'smooth'});
        };

        return (
            events.map(event => (
                <Col key={event.title} md={6} lg={4}>
                    <Card className={`card__event ${event.playing && "isPlaying"}`}>
                        <div className="card__img">
                            {event.type === '2vs2' &&
                                <Card.Img variant="top" src="/event-images/winter.jpg"/>
                            }

                            {event.type !== '2vs2' &&
                                <Card.Img variant="top" src="/event-images/winter2.jpg"/>
                            }

                            <span className="card__event-type">{event.type}</span>
                        </div>
                        <Card.Body style={{paddingTop: "0.75rem", paddingLeft: "0.8rem", paddingRight: "0.75rem"}}>
                            <div>
                                <h6>
                                    {login &&
                                        <a href={"https://www.facebook.com/events/" + event.id}>
                                            {event.title}
                                        </a>
                                    }
                                    {!login &&
                                        <span style={{color: "#666"}}>{event.title}</span>
                                    }
                                </h6>
                            </div>
                            <small className="text-muted">
                                <p>
                                    {event.time} - {event.endTime} | {event.date}<br/>
                                    ({event.location})
                                    <br/>
                                    <b>Going: {event.count} / {event.max}</b>
                                    {event.count >= event.max && !event.isEnded &&
                                        <>
                                            <br/>
                                            <span style={{color: "#F60"}}>
                                                We are full for now!
                                                <br/>However you can still join.
                                            </span>
                                        </>
                                    }
                                    {event.isEnded &&
                                        <>
                                            <br/>
                                            (Event ended)
                                        </>
                                    }
                                </p>
                            </small>
                            {!event.isEnded && !login &&
                                <Button variant="outline-secondary" onClick={scrollTop}>
                                    Login to Join & See players
                                </Button>
                            }
                            {!event.isEnded && login && !event.playing &&
                                <Button variant="success"
                                        onClick={() => this.actionJoinCancel(event.id, "join")}>
                                    Join event
                                </Button>
                            }
                            {!event.isEnded && login && event.playing &&
                                <Button variant="warning"
                                        onClick={() => this.actionJoinCancel(event.id, "cancel")}>
                                    Cancel participation
                                </Button>
                            }

                            {login && userData &&
                                <EventPlayers event={event} userData={userData}/>
                            }
                        </Card.Body>
                    </Card>
                </Col>
            ))
        );
    }

    actionJoinCancel(eventId, type) {
        if (type === "cancel") {
            const isConfirmed = window.confirm("Are you sure?");
            if (!isConfirmed) {
                return;
            }
        }

        const requestOptions = {
            method: 'POST',
            credentials: 'include',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({eventId: eventId, accessToken: this.props.accessToken, type: type})
        };
        fetch('https://app.sparitesports.com/api/manage', requestOptions)
            .then(response => response.json())
            .then((response) => {
                    const events = this.props.events;
                    const goingCount = response.goingCount;

                    if (!response.goingCount) {
                        window.location.reload(false);
                    }

                    events.forEach((event) => {
                        if (event.id === eventId) {
                            event.playing = (type === 'join');
                            event.count = goingCount;
                            event.players = response.players;
                            event.playersList = response.playersList;
                        }
                    });
                    this.setState({events: events});
                }
            );
    }
}
