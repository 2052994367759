import React from 'react';
import {GoogleOAuthProvider} from '@react-oauth/google';
import {GoogleLogin} from '@react-oauth/google';
import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image';
import Container from 'react-bootstrap/Container';
import Events from '../Events';
import UpdateInfo from '../UpdateInfo';
import Alert from "react-bootstrap/Alert";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            authorized: null, userData: null, error: null,
        };

        this.responseFacebook = this.responseFacebook.bind(this);
        this.responseGoogle = this.responseGoogle.bind(this);
        this.getMe = this.getMe.bind(this);
        this.logoutAction = this.logoutAction.bind(this);
    }

    isFacebookApp() {
        const ua = navigator.userAgent || navigator.vendor || window.opera;
        return ua.indexOf('FBAN') > -1 || ua.indexOf('FBAV') > -1 || ua.indexOf('Instagram') > -1;
    }

    responseFacebook(response) {
        if (response.accessToken) {
            this.setState({
                userData: {
                    accessToken: response.accessToken
                }
            });
        }
        this.getMe();
    }

    responseGoogle(response) {
        if (response.credential) {
            this.setState({
                userData: {
                    accessToken: response.credential,
                    loginType: "google"
                }
            });
        }
        this.getMe();
    }

    getMe() {
        const requestOptions = {
            method: 'POST', credentials: 'include'
        };
        if (this.state.userData && this.state.userData.accessToken) {
            // requestOptions.headers = {'Content-Type': 'application/json'};
            requestOptions.headers = {'Content-Type': 'application/x-www-form-urlencoded'};
            requestOptions.body = "token=" + this.state.userData.accessToken
                + "&loginType=" + this.state.userData.loginType;
        }
        fetch('https://app.sparitesports.com/api/me', requestOptions)
            .then(res => res.json())
            .then((response) => {
                if (response.auth) {
                    this.setState({
                        authorized: true, userData: {
                            uid: response.uid,
                            user_id: response.user_id,
                            name: response.name,
                            picture: response.picture,
                            phone: response.phone,
                            email: response.email,
                            notifyNewEvent: response.notifyNewEvent
                        }
                    });
                } else {
                    this.setState({
                        authorized: false, userData: null
                    });
                }
            }).catch(() => {
            this.setState({error: true});
        });
    }

    logoutAction(e) {
        e.preventDefault();
        fetch('https://app.sparitesports.com/logout', {
            method: 'GET', credentials: 'include'
        }).then(() => {
            this.getMe();
        });
    }

    componentDidMount() {
        this.getMe();
    }

    render() {
        const {authorized, userData, error} = this.state;

        return (<Container>
            {this.isFacebookApp() && <Alert variant="danger" className="text-center">
                Are you on Facebook Browser? In case you cannot login, try to press [...] and Open in
                Chrome or Safari outside Facebook App.
            </Alert>}
            <Card>
                <Card.Header style={{minHeight: '75px'}}>
                    {authorized !== null && !authorized && <div>

                        <Col lg={3}>
                            <GoogleOAuthProvider
                                clientId="986059470729-dhe7tc4h8tl6firuuhl7tqsofitluu50.apps.googleusercontent.com">
                                <GoogleLogin
                                    onSuccess={this.responseGoogle}
                                    onError={() => {
                                        alert('Try again! :)');
                                    }}
                                    useOneTap={true}
                                    uxMode='redirect'
                                />
                            </GoogleOAuthProvider>
                        </Col>

                    </div>}

                    {authorized && <Row>
                        <Col md={8} lg={8}>
                            <UpdateInfo userData={userData}/>
                        </Col>
                        <Col md={4} lg={4}>
                            <hr className="d-md-none"/>
                            <h6 style={{marginTop: '12px', textAlign: 'center'}}>
                                {/*<Image src={userData.picture} roundedCircle*/}
                                {/*       style={{height: '50px', width: '50px'}}/>*/}
                                {/*&nbsp;*/}
                                <span style={{
                                    whiteSpace: 'nowrap', verticalAlign: 'middle'
                                }}>{userData.name} </span>

                                <button className="btn btn-outline-dark" style={{width: "45px", fill: '#666'}}
                                        onClick={this.logoutAction}>
                                    {/*Logout*/}

                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                        <path
                                            d="M497 273L329 441c-15 15-41 4.5-41-17v-96H152c-13.3 0-24-10.7-24-24v-96c0-13.3 10.7-24 24-24h136V88c0-21.4 25.9-32 41-17l168 168c9.3 9.4 9.3 24.6 0 34zM192 436v-40c0-6.6-5.4-12-12-12H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h84c6.6 0 12-5.4 12-12V76c0-6.6-5.4-12-12-12H96c-53 0-96 43-96 96v192c0 53 43 96 96 96h84c6.6 0 12-5.4 12-12z"/>
                                    </svg>

                                </button>
                            </h6>
                        </Col>
                    </Row>}

                    {error && <span>:(<br/>Please refresh the page!</span>}
                </Card.Header>
            </Card>
            <br/>

            {authorized !== null &&
                <Events authorized={authorized} key={String(authorized)} userData={userData}></Events>}

        </Container>);
    }
}

export default App;
