import React from 'react';
import EventPlayersTeams from "./EventPlayersTeams";

export default class EventPlayers extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            playersActive: 1,
            playerNewTeam: []
        };
        this.playerSelectLimit = this.props.event.type.charAt(0);

        // this.clickPlayer = this.clickPlayer.bind(this);
        // this.actionSaveTeam = this.actionSaveTeam.bind(this);
    }

    render() {
        const {event, userData} = this.props;

        return (
            <>
                <hr/>
                {this.playersActiveHtml()}
                <div style={{textAlign: "left", fontSize: "13px"}}>
                    {this.htmlPlayerLineAttnedees(event)}
                    {this.htmlPlayerLineReservist(event)}
                    {this.genderLine(event)}
                    <EventPlayersTeams event={event} userData={userData}/>
                </div>
            </>
        );
    }

    playersActiveHtml = () => {
        if (this.state.playersActive > 1) {
            return <>
                <button className="btn btn-info" onClick={this.actionSaveTeam}>Save team</button>
                <br/><br/>
            </>
        }
    }

    htmlPlayerLineAttnedees = (event) => {
        if (!event.playersList) {
            return;
        }

        let html = event.playersList.map((player, i) => {
            if (player.type !== 'attendee') {
                return true;
            }
            return this.htmlPlayerLine(player, i)
        });
        return <>
            Participants (rank):
            {html}
        </>
    }

    htmlPlayerLine(player, i) {
        return <div key={player.user_id}
                    className={`player ${player.user_id === this.props.userData.user_id ? "me" : ''}`}
                    data-uid={player.user_id}
                    onClick={this.clickPlayer}>
            {i + 1}. <span className="grayed">{player.gender}</span> {player.name}
            <span className="grayed"> ({player.rank})
                {player.team ? ' - ' + player.team : ''}
            </span>
        </div>
    }

    htmlPlayerLineReservist = (event) => {
        if (!event.playersList) {
            return;
        }
        let html = [];
        event.playersList.forEach((player, i) => {
            if (player.type !== 'reservist') {
                return false;
            }
            html.push([
                <div key={player.user_id}
                     className={`player ${player.user_id === this.props.userData.user_id ? "me" : ''}`}
                     data-uid={player.user_id}
                     onClick={this.clickPlayer}>
                    {i + 1}. <span className="grayed">{player.gender}</span> {player.name}
                    <span className="grayed"> ({player.rank})
                        {player.team ? ' - ' + player.team : ''}
                    </span>
                </div>
            ]);
        });

        if (html.length > 0) {
            return <>
                <br/>Reserve:
                {html}
            </>
        }
    }

    genderLine = (event) => {
        if (!event.playersList) {
            return;
        }

        let female = 0;
        let male = 0;
        event.playersList.forEach((player) => {
            if (player.gender === "S") {
                female++;
            } else if (player.gender === "V") {
                male++;
            }
        });

        return <>
            <br/>
            <div className="grayed">
                Female: {female} Male: {male}
            </div>
        </>;
    }


    canSelect() {
        // console.log("playersActive", this.state.playersActive)
        // console.log("playerSelectLimit", this.playerSelectLimit)
        // console.log("playing", this.event.playing)
        return this.state.playersActive < this.playerSelectLimit && this.props.event.playing
    }

    clickPlayer = (e) => {
        return false;
        /*
        const el = e.currentTarget;
        const uid = el.dataset.uid;

        if (el.classList.contains('active')) {
            el.classList.remove('active');
            this.state.playerNewTeam.filter(item => item !== uid);
            this.setState({'playersActive': this.state.playersActive - 1});

        } else if (this.canSelect()) {
            el.classList.add('active');
            this.state.playerNewTeam.push(uid);
            this.setState({'playersActive': this.state.playersActive + 1});
        }
        */
    }

    /*
    actionSaveTeam = () => {
        const teamName = prompt("Team name", "");
        if (!teamName) {
            return;
        }
        console.log("playerNewTeam", this.state.playerNewTeam);

        const requestOptions = {
            method: 'POST',
            credentials: 'include',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                eventId: this.event.id,
                teamName: teamName,
                players: this.state.playerNewTeam
            })
        };
        fetch('https://app.sparitesports.com/api/team', requestOptions)
            .then(response => response.json())
            .then((response) => {
                    console.warn(response);
                }
            );

        // reset state
        console.info("playerNewTeam 2", this.state.playerNewTeam);

        this.setState({
            playersActive: 1,
            playerNewTeam: []
        });

        this.forceUpdate();
    }
    */

}
